import React from 'react';
import { Wave } from './wave';

import styles from './wave.module.scss'

type Props = {
};

export const Waves: React.FC<Props> = () => {
  return <div className={styles.wavesWrapper}><div className={styles.waves}>
    <Wave />
    <Wave />
    <Wave />
    <Wave />
    <Wave />
    <Wave />
  </div></div>;
};
