export enum SubscriptionStatusEnum {
  Active = 'active',
  Inactive = 'inactive'
}

type SubscriptionStatusType = {
  label: SubscriptionStatusEnum
  value: string
}

export const SubscriptionStatus: { [key: string]: SubscriptionStatusType } = {
  Active: { label: SubscriptionStatusEnum.Active, value: '1' },
  Inactive: { label: SubscriptionStatusEnum.Inactive, value: '0' }
}
