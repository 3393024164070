import classNames from 'classnames'
import Link from 'next/link'
import React, { ChangeEvent, useState } from 'react'
import { Container, Row, Col, Form, FormGroup, Input, Spinner, Button, Label } from 'reactstrap'
import { fetchApi } from 'src/client/common/api-caller/api-caller'
import { Poster } from 'src/client/common/poster/poster.component'
import { translate } from 'src/client/common/translatable/translatable'
import { Waves } from 'src/client/common/waves/waves'
import { createLink, menuFlavorsLinks, Routes } from 'src/client/routes'

import { FooterProps } from './@types/footer-props.types'
import styles from './footer.module.scss'

const Footer: React.FC<FooterProps> = ({ posterName, posterImgAlt, hasNewsletter }) => {
  const t = translate

  const [email, setEmail] = useState('')
  const [formError, setFormError] = useState(false)
  const [apiError, setApiError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [emailSubmitted, setEmailSubmitted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormError(false)
    setEmail(e.target.value)
  }

  const validateEmail = () => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}/.test(email) && email !== ''

  const resetErrors = () => {
    setApiError(false)
    setFormError(false)
  }

  const submitEmail = async (event) => {
    event.preventDefault()

    resetErrors()

    if (!validateEmail() && !checked) {
      setFormError(true)
      setErrorMessage('Podaj prawidłowy adres e-mail oraz zaznacz zgodę')

      return
    }

    setLoading(true)

    try {
      const newsletterDate = new Date().toISOString()

      const response = await fetchApi('/leads', {
        method: 'post',
        body: JSON.stringify({
          email,
          fieldValues: [
            {
              field: 'Newsletter Source',
              value: newsletterDate
            },
            {
              field: 'Marketing Agreement',
              value: checked
            },
            {
              field: 'Marketing Agreement Date',
              value: newsletterDate
            }
          ]
        })
      })

      if (!response.ok) {
        throw new Error()
      } else {
        setEmailSubmitted(true)
      }
    } catch (error) {
      setErrorMessage('Coś poszło nie tak, spróbuj ponownie za chwilę')
      setApiError(true)
    }
    setLoading(false)
  }

  const toggleCheck = () => setChecked(!checked)

  const isError = () => formError || apiError

  return (
    <>
      {posterName && <Poster posterName={posterName} posterImgAlt={posterImgAlt} />}

      <Waves />

      <div className="bg-purple text-footer-grey">
        <Container className="text-footer-grey text-center">
          {/* {hasNewsletter && (
            <Row>
              <Col style={{ minHeight: '270px' }}>
                {!emailSubmitted ? (
                  <>
                    <div className="mt-100-px mt-mobile-80-px text-center text-footer-grey px-2 mb-4 font-size-38 font-size-mobile-26">
                      <div className="font-weight-700 text-footer-grey">
                        {t('Oferty specjalne, porady ekspertów ')}
                      </div>

                      <div className="font-weight-700 text-footer-grey">
                        {t('i solidna gromadka psijaciół czekają na Ciebie!')}
                      </div>
                    </div>

                    <Form onSubmit={submitEmail}>
                      <FormGroup>
                        <Col xs="12" md="7" lg="5" className="mx-auto">
                          <div>
                            {loading ? (
                              <Spinner color="primary" />
                            ) : (
                              <div className="bg-white rounded-pill p-1 d-flex">
                                <Input
                                  id="email"
                                  value={email}
                                  onChange={onEmailChange}
                                  className={classNames(['py-4', styles.footerInput])}
                                  type="email"
                                  placeholder="Twój adres email"
                                />

                                <Button
                                  className={styles.submitButton}
                                  disabled={!checked || !email}
                                >
                                  <img
                                    alt="wyślij"
                                    src="/svgs/nl_btn.svg"
                                    width="48"
                                    id="input-arrow"
                                    onMouseEnter={() =>
                                      document
                                        .getElementById('input-arrow')
                                        .setAttribute('src', '/svgs/nl_btn_h.svg')}
                                    onMouseLeave={() =>
                                      document
                                        .getElementById('input-arrow')
                                        .setAttribute('src', '/svgs/nl_btn.svg')}
                                  />
                                </Button>
                              </div>
                            )}

                            <div
                              className={`text-danger font-weight-bold mt-3 ${
                                isError() ? 'visible' : 'invisible'
                              }`}
                            >
                              {t(errorMessage)}
                            </div>
                          </div>
                        </Col>
                      </FormGroup>

                      <Col>
                        <FormGroup check className="pl-0">
                          <Label
                            check
                            className="my-checkbox d-flex align-items-center text-left text-md-center"
                            style={{ paddingLeft: 10 }}
                          >
                            <Input type="checkbox" onChange={toggleCheck} checked={checked} />
                            <span className="checkmark checkmark-primary mt-n1" />

                            <span
                              className="font-weight-400 text-sand ml-3"
                              style={{ fontSize: '11px', lineHeight: '12px' }}
                            >
                              {translate(
                                'Wyrażam zgodę na przetwarzanie danych osobowych udostępnionych w formularzu w celu otrzymywania drogą mailową Newslettera poradnikowego i marketingowego od Human Grade Sp. z o.o.'
                              )}
                            </span>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Form>
                  </>
                ) : (
                  <h3 className="font-weight-600 mt-5">
                    {t('Dziękujemy za zapisanie się do naszego newslettera!')}
                  </h3>
                )}
              </Col>
            </Row>
          )} */}

          <Row>
            <Col xs="12" md={{ size: 4, order: 2 }}>
              <div className="my-auto">
                <div className="font-weight-700 mt-5 mb-4 text-sand font-size-22">{`${t('Masz pytania')}?`}</div>

                <p className="text-primary font-weight-700 mb-5">
                  <a className={styles.noHover} href="mailto:kontakt@piesotto.pl">
                    {t('kontakt@piesotto.pl')}
                  </a>
                </p>

                <div className="mt-4 mb-5">
                  <p className="text-primary font-weight-700">
                    <a className={styles.noHover} href="tel:222452940">
                      22 245 29 40
                    </a>
                  </p>

                  <div className="text-footer-grey font-size-13">
                    {t('pon. - pt. 9:30 - 17:00')}
                  </div>
                </div>
              </div>
            </Col>

            <Col xs="12" md={{ size: 4, order: 3 }} className="mb-mobile-20-px">
              <div className="pt-xs-4 pt-md-5 mt-md-5 my-auto text-center text-lg-right">
                <a
                  href="https://www.instagram.com/piesotto/?hl=pl"
                  className={classNames(['mx-3 text-footer-grey', styles.social])}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    width="32"
                    src="/svgs/instagram.svg"
                    role="link"
                    alt="instagram"
                    className={classNames(['mb-3'])}
                  />
                </a>

                <a
                  href="https://www.facebook.com/piesotto/"
                  rel="noreferrer"
                  target="_blank"
                  className={classNames(['mx-3 text-footer-grey', styles.social])}
                >
                  <img
                    width="32"
                    src="/svgs/facebook.svg"
                    role="link"
                    alt="facebook"
                    className={classNames(['mb-3'])}
                  />
                </a>

                <a
                  href="https://www.tiktok.com/@piesotto"
                  className={classNames(['mx-3 text-footer-grey', styles.social])}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    height="32"
                    src="/svgs/tiktok-brands.svg"
                    role="link"
                    alt="tic toc"
                    className={classNames(['mb-3'])}
                  />
                </a>

                <a
                  href="https://www.youtube.com/channel/UCo9OcUjhVMU7Ei1hV3WMLZw"
                  className={classNames(['mx-3 text-footer-grey', styles.social])}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    width="75"
                    src="/svgs/youtube.svg"
                    role="link"
                    alt="youtube"
                    className={classNames(['mb-3'])}
                  />
                </a>
              </div>
            </Col>

            <Col xs="12" md={{ size: 4, order: 1 }}>
              <div className="d-flex flex-column justify-content-center my-auto mt-md-5 text-md-left">
                <div className="font-weight-700 my-2 text-sand">
                  <a href={createLink(Routes.Pages_Recipes)} hidden>
                    {t('Nasze menu')}
                  </a>

                  <Link href={createLink(Routes.Pages_Recipes)}>
                    <span className={styles.siteLink}>{t('Nasze menu')}</span>
                  </Link>
                  <div className={classNames([styles.flavorSiteLink, 'd-flex', 'flex-column'])}>
                    {menuFlavorsLinks.map(link =>
                      (<Link href={createLink(link.route)}>
                        <span className={classNames(['font-weight-light'])}>{link.label}</span>
                      </Link>))}
                  </div>
                </div>

                <div className="font-weight-700 my-2 text-sand">
                  <a href={createLink(Routes.Pages_HowItWorks)} hidden>
                    {t('Jak to działa')}
                  </a>

                  <Link href={createLink(Routes.Pages_HowItWorks)}>
                    <span className={styles.siteLink}>{t('Jak to działa')}</span>
                  </Link>
                </div>

                <div className="font-weight-700 my-2 text-sand">
                  <a href={createLink(Routes.Pages_About)} hidden>
                    {t('O Piesotto')}
                  </a>

                  <Link href={createLink(Routes.Pages_About)}>
                    <span className={styles.siteLink}>{t('O Piesotto')}</span>
                  </Link>
                </div>

                <div className="font-weight-700 my-2 text-sand">
                  <a href={createLink(Routes.Pages_Faq)} hidden>
                    {t('Często zadawane pytania')}
                  </a>

                  <Link href={createLink(Routes.Pages_Faq)}>
                    <span className={styles.siteLink}>{t('Często zadawane pytania')}</span>
                  </Link>
                </div>

                <div className="font-weight-700 my-2 text-sand">
                  <a href={createLink(Routes.Pages_Blog)} hidden>
                    {t('Blog Piesotto')}
                  </a>

                  <Link href={createLink(Routes.Pages_Blog)}>
                    <span className={styles.siteLink}>{t('Blog Piesotto')}</span>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="6">
              <div className="mt-5 mb-3 text-md-left font-size-13 font-weight-700 text-sand">
                <div>&copy; 2024 Piesotto</div>
              </div>
            </Col>

            <Col xs="12" md="6">
              <div className="text-footer-grey h-100 font-weight-600 text-center d-flex justify-content-around justify-content-md-end pb-3 align-items-end font-size-13">
                <a href={createLink(Routes.Pages_Regulations)} hidden>
                  {t('Regulamin')}
                </a>

                <Link href={createLink(Routes.Pages_Regulations)}>
                  <span className="cursor-pointer mx-md-2">{t('Regulamin')}</span>
                </Link>

                <a href={createLink(Routes.Pages_PrivacyPolicy)} hidden>
                  {t('Polityka prywatności i pliki cookies')}
                </a>

                <Link href={createLink(Routes.Pages_PrivacyPolicy)}>
                  <span className="cursor-pointer mx-md-2">
                    {t('Polityka prywatności i pliki cookies')}
                  </span>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Footer
