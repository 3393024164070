import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { handleViewport } from '../viewport-detector/viewport-handler'
import { ViewportTypeEnum } from '../viewport-detector/viewport-type.enum'

import { PosterNamesEnum } from './poster-names.enum'
import styles from './poster.module.scss'

type Props = {
  posterName: PosterNamesEnum
  posterImgAlt: string
}

const captions = {
  [PosterNamesEnum.HowItWorks]: [
    'Marzy mi się, żeby mój pies zawsze patrzył na mnie tak jak na Piesotto'
  ],
  [PosterNamesEnum.Recipes]: ['By niejadek zjadł obiadek'],
  [PosterNamesEnum.About]: ['Szybciej człowiek,', 'czas ucieka piesek czeka'],
  [PosterNamesEnum.Home]: [''],
  [PosterNamesEnum.FAQ]: [
    'Już niedługo Twój pies będzie jadł tak dobrze jak Ty, a może nawet lepiej'
  ]
}

export const Poster: React.FC<Props> = ({ posterName, posterImgAlt }) => {
  const [viewportType, setViewportType] = useState<ViewportTypeEnum>(ViewportTypeEnum.Desktop)
  const [rotation, setRotation] = useState<number>(0)
  const [direction, setDirection] = useState<number>(0)

  useEffect(() => {
    handleViewport(setViewportType)
    setRotation(3 + Math.floor(Math.random() * 3))
    setDirection(Math.round(Math.random()))

    window.addEventListener('resize', () => handleViewport(setViewportType))
  }, [])

  const url = `/images/posters/piesotto_poster_${viewportType}_${posterName}.jpg`

  return (
    <div className="d-flex position-relative">
      <img className="img-fluid" src={url} alt={posterImgAlt} />
      <div
        className="d-flex w-100 position-absolute justify-content-center align-items-center"
        style={{ top: 0, bottom: 0 }}
      >
        <div
          className={classNames([
            'rotation-n3 d-inline-flex flex-column align-items-center',
            styles.captionWrapper
          ])}
        >
          {captions[posterName].map(caption => (
            <div key={caption}>
              <span className={classNames(styles.caption)}>{caption}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
