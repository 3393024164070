import React from 'react'
import styles from './wave.module.scss'

type Props = {}

// Generated by https://smooth.ie/blogs/news/svg-wavey-transitions-between-sections
export const Wave: React.FC<Props> = () => {
  return (
    <div className={styles.wave}>
      <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%', fill: 'inherit', stroke: 'inherit'}}>
        <path
          d="M0.00,49.98 C252.54,246.20 258.74,-88.31 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          style={{ stroke: 'inherit', fill: 'inherit'}}
        ></path>
      </svg>
    </div>
  )
}
