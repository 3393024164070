declare global {
  interface Window {
    fbq?: any
  }
}

export const FB_PIXEL_ID = '1206727352844504'

export const pageview = () => {
  // window.fbq('track', 'PageView')
}

export const fbpStandardEvent = (name, options = {}, eventID) => {
  // if (!window.fbq) {
  //   return
  // }

  // window.fbq('track', name, options, { eventID })
}

export const fbpEvent = (name, options = {}, eventID) => {
  // if (!window.fbq) {
  //   return
  // }

  // window.fbq('trackCustom', name, options, { eventID })
}
