import { FoodFlavorEnum } from 'piesotto-shared/dog-dtos/food-flavor.enum'

import { CustomerPlanTypeEnum } from '../../../shared/customer-dtos/customer-plan-enum'
import { SubscriptionStatusEnum } from '../../../shared/customer-dtos/subscription-status-enum'
import { PackageModifierEnum } from '../../../shared/dog-dtos/package-modifier.enum'
import { OrderStatusEnum } from '../../../shared/orders-dtos/order-status.enum'
import { PaymentStatusEnum } from '../../../shared/payments-dtos/payment-status.enum'

export const translate = (content: string, context: Record<string, any> = []) =>
  Object.keys(context).reduce((prev, key) => {
    const regex = new RegExp(`{{${key}}}`, 'g')
    prev = prev.replace(regex, context[key])

    return prev
  }, content.toString())

export const adminTranslate = translate

export const paymentStatusDictionary = {
  [PaymentStatusEnum.Failed]: 'nieudany',
  [PaymentStatusEnum.Success]: 'udany',
  [PaymentStatusEnum.Unknown]: 'nieznany',
  [PaymentStatusEnum.Pending]: 'w trakcie',
  [PaymentStatusEnum.Cancelled]: 'anulowany',
  [PaymentStatusEnum.Invoice]: 'faktura'
}
export const translatePaymentStatus = (status: PaymentStatusEnum) => paymentStatusDictionary[status]

export const translatePlanType = (planType: CustomerPlanTypeEnum) =>
  ({
    [CustomerPlanTypeEnum.OneTime]: 'jednorazowy',
    [CustomerPlanTypeEnum.Subscription]: 'subskrypcja',
    [CustomerPlanTypeEnum.Mix]: 'mix'
  }[planType])

export const translateSubscriptionStatus = (planType: SubscriptionStatusEnum) =>
  ({
    [SubscriptionStatusEnum.Active]: 'aktywna',
    [SubscriptionStatusEnum.Inactive]: 'nieaktywna'
  }[planType])

export const deliveryStatusDictionary = {
  [OrderStatusEnum.Pending]: 'oczekujące',
  [OrderStatusEnum.Canceled]: 'anulowane',
  [OrderStatusEnum.Done]: 'zrealizowane'
}

export const translateDeliveryStatus = (status: OrderStatusEnum) => deliveryStatusDictionary[status]

export const packageModifierDictionary = {
  [PackageModifierEnum.Smaller]: 'mała',
  [PackageModifierEnum.Default]: 'domyślna',
  [PackageModifierEnum.Bigger]: 'większa',
  [PackageModifierEnum.Bigest]: 'największa'
}

export const translatePackageModifier = (modifier: PackageModifierEnum) =>
  packageModifierDictionary[modifier]
