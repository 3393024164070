import * as Sentry from '@sentry/nextjs'
import React from 'react'
import { FallbackProps } from 'react-error-boundary'
import { Button, Col, Container, Row } from 'reactstrap'
import { translate } from 'src/client/common/translatable/translatable'
import Footer from 'src/client/modules/static-pages/footer/footer.component'
import styles from 'src/client/modules/static-pages/footer/footer.module.scss'
import { Routes } from 'src/client/routes'

export const LoginFallback = ({ error }: FallbackProps) => {
  navigator.clipboard.writeText(
    `Komunikat błędu ze strony piesotto.pl: ${error.message} | ${error.stack}`
  )

  Sentry.captureException(error)

  return (
    <>
      <div className="bg-snack">
        <Container>
          <Row>
            <Col className="text-center text-purple">
              <img src="/svgs/404.svg" width={280} alt="no-page" />
              <div className="font-size-54 font-size-mobile-31 font-weight-800 mt-30-px mb-30-px">
                {translate('Coś poszło nie tak')}
              </div>

              <div className="font-size-18 mb-100-px mb-mobile-60-px">
                {translate(
                  'Doszło do sytuacji, której nie przewidzieliśmy wcześniej. W schowku umieściliśmy informacje, które pomogą nam zdiagnozować i rozwiązać problem. Znajdując chwilę na przesłanie nam maila, gdzie w treści używając "Wklej" znalazłyby się dodatkowe dane, znacznie przyspieszyłoby dostarczenie Ci rozwiązania. Dziekujemy. Nasz adres: '
                )}
                <a className={styles.noHover} href="mailto:kontakt@piesotto.pl">
                  {translate('kontakt@piesotto.pl')}
                </a>
              </div>

              <div>
                <Button
                  className="mb-80-px"
                  color="primary"
                  onClick={() => {
                    window.location.href = Routes.Pages_Home
                  }}
                >
                  {translate('Przejdź do strony głównej')}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer hasNewsletter />
    </>
  )
}
