export const GA_TRACKING_ID = 'GTM-5DZNRRC'

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value
  })
}

export enum GAEvent {
  ACTIVITY_TYPE = 'calculator_9',
  ADD_PAYMENT_INFO = 'add_payment_info',
  ADD_SHIPPING_INFO = 'add_shipping_info',
  ADD_TO_CART = 'add_to_cart',
  ALLERGIES = 'calculator_11',
  ALLERGIES_A = 'calculator_11a',
  APPETITE = 'appetite',
  BEGIN_CHECKOUT = 'begin_checkout',
  CHOOSE_BREED = 'calculator_3',
  CHOOSE_SEX = 'calculator_6',
  DOG_AGE = 'calculator_4',
  DOG_WEIGHT = 'calculator_5',
  ENTER_DOGS = 'calculator_2',
  FOOD_PREFERENCES = 'calculator_8',
  FOOD_PREFERENCES_A = 'calculator_8a',
  FOOD_PREFERENCES_B = 'calculator_8b',
  HEALTH_ISSUES = 'calculator_12',
  HEALTH_ISSUES_A = 'calculator_12a',
  LETS_MEET = 'calculator_1',
  LOGIN = 'login',
  PRIORITIES = 'priorities',
  PROFILE_TYPE = 'calculator_10',
  PURCHASE = 'purchase',
  SIGN_UP = 'sign_up',
  START_PROCESS = 'calculator_0',
  STERILIZATION = 'calculator_7',
  VIEW_ITEM = 'view_item'
}
