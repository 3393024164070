import { createTypedLink } from 'piesotto-shared/routing/create-typed-link'

export enum Routes {
  MyAccount_GetSubscription = '/moje-konto/przedluz-subskrypcje',
  MyAccount_GetSubscriptionCheckout = '/moje-konto/przedluz-subskrypcje-podsumowanie',
  MyAccount_MyData = '/moje-konto/moje-dane',
  MyAccount_MyDogs = '/moje-konto/moje-psy',
  MyAccount_MyPayments = '/moje-konto/moje-platnosci',
  MyAccount_MyPlan = '/moje-konto/moj-plan',
  MyAccount_ReOrderOneTimeCheckout = '/moje-konto/zamow-ponownie-podsumowanie',
  Pages_About = '/o-piesotto',
  Pages_Blog = 'http://blog.piesotto.pl/',
  Pages_Faq = '/faq',
  Pages_Home = '/',
  Pages_HowItWorks = '/jak-to-dziala',
  Pages_InterruptedBreak = '/przerwa-techniczna',
  Pages_Login = '/login',
  Pages_Logout = '/wyloguj-sie',
  Pages_PrivacyPolicy = '/polityka-prywatnosci',
  Pages_Recipes = '/przepisy',
  Pages_Recipes_Beef = '/przepisy/wolowina',
  Pages_Recipes_Duck = '/przepisy/kaczka',
  Pages_Recipes_Fish = '/przepisy/ryba',
  Pages_Recipes_Turkey = '/przepisy/indyk',
  Pages_Recipes_Lamb = '/przepisy/jagniecina',
  Pages_Regulations = '/regulamin',
  Pages_ResetPassword = '/reset-password',
  Pages_YourStories = '/wasze-historie',
  Plan_ActivityType = '/plan/aktywnosc/:dog',
  Plan_AdultDogWeight = '/plan/podaj-wage-doroslego/:dog',
  Plan_Allergies = '/plan/alergie/:dog',
  Plan_Appetite = '/plan/apetyt/:dog',
  Plan_CalculatingDiets = '/plan/obliczanie-diet',
  Plan_ChooseBreed = '/plan/wybierz-rase/:dog',
  Plan_ChooseFlavors = '/plan/wybierz-smaki',
  Plan_ChooseSex = '/plan/wybierz-plec/:dog',
  Plan_CustomerData = '/plan/dane-klienta',
  Plan_DogAge = '/plan/podaj-wiek/:dog',
  Plan_DogSummary = '/plan/podsumowanie/:dog',
  Plan_DogWeight = '/plan/podaj-wage/:dog',
  Plan_EnterDogs = '/plan/wprowadz-psy',
  Plan_FoodPreferences = '/plan/preferencje-zywieniowe/:dog',
  Plan_HealthIssues = '/plan/problemy-zdrowotne/:dog',
  Plan_LetsMeet = '/plan/stworz-plan',
  Plan_Priorities = '/plan/priorytety',
  Plan_ProfileType = '/plan/figura/:dog',
  Plan_Sterilization = '/plan/sterylizacja/:dog'
}

export const createLink = (route: Routes, context: Record<string, any> = []) =>
  createTypedLink<Routes>(route, context)

export const menuFlavorsLinks = [
  {
    route: Routes.Pages_Recipes_Beef,
    label: 'Wołowina'
  },
  {
    route: Routes.Pages_Recipes_Turkey,
    label: 'Indyk'
  },
  {
    route: Routes.Pages_Recipes_Duck,
    label: 'Kaczka'
  },
  {
    route: Routes.Pages_Recipes_Fish,
    label: 'Ryba'
  },
  {
    route: Routes.Pages_Recipes_Lamb,
    label: 'Jagnięcina'
  },
]
